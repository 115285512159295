<script>
import TablePage from '../../../../../components/table_page';
import request from '../../../../../utils/request';
import Form from './form';

import * as utils from '../../../utils/index';

export default {
  name: 'table-component',
  extends: TablePage,
  components: {
    Form,
  },
  data() {
    return {
      /** @desc 表单配置参数 */
      formConfig: {},
      /** @desc 请假参数配置 */
      formData: {},
      /** @desc 弹窗配置参数 */
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  async created() {
    this.searchFormData.yearMonth = utils.dateFormat('YYYY-mm', new Date());
    await this.getConfigList('sfa_attendance_repair_clock_application');
  },
  methods: {
    // 判断是否有‘yearMonth’字段
    hasYearMonth() {
      const index = this.searchList.findIndex((item) => item.field === 'yearMonth');
      if (index > -1) {
        return true;
      }
      return false;
    },
    // 获取列表数据前置函数
    beforeGetList() {
      if (this.hasYearMonth()) {
        this.formData.yearMonth = this.searchFormData.yearMonth || null;
      } else if (this.searchFormData.yearMonth) {
        delete this.searchFormData.yearMonth;
      }
      return true;
    },
    /** @override 重写重置按钮 */
    beforeSearchEvent({ $event }) {
      if ($event.type === 'reset' && this.hasYearMonth()) {
        this.searchFormData.yearMonth = utils.dateFormat('YYYY-mm', new Date());
        this.formData.yearMonth = this.searchFormData.yearMonth;
      }
      return true;
    },
    cellClick({ row, column }) {
      if (column.property === 'realName') {
        this.formConfig.type = 'view';
        this.formConfig.id = row.id;
        this.modalConfig.title = '查看详情';
        this.openFull();
      }
    },
    modalClick({ val, row }) { },
  },
};
</script>
